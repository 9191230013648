import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          src="//tt.mbww.com/tt-1ee4e10c0c7e1bd5ff9cd70d1f3c03521abf21ea7ee45aa92deab16f87af4dbb.js"
          async
        />
        <p id="skip-link">
          <a href="#main-menu" className="element-invisible element-focusable">
            Jump to navigation
          </a>
        </p>
        <div id="mobile-sidebar">
          <div className="mobile-logo-wrapper">
            <a href="/" title="Inicio" rel="home" className="mobile-logo">
              <img
                src="/sites/all/themes/custom/iniston_zen/logo.png"
                alt="Inicio"
              />
            </a>
          </div>
          <div className="region region-mobile-sidebar">
            <div
              id="block-search-form"
              className="block block-search first odd"
              role="search"
            >
              
            </div>
            <div
              id="block-system-main-menu"
              className="block block-system block-menu last even"
              role="navigation"
            >
              <ul className="menu">
                <li className="menu__item is-expanded first expanded">
                  <a href="/la-tos/" className="menu__link">
                    La tos
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-expanded first expanded">
                      <a href="/la-tos/que-es-la-tos/" className="menu__link">
                        ¿Qué es la tos?
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/la-tos/que-es-la-tos/"
                            className="menu__link"
                          >
                            Definición
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/que-es-la-tos/vivencia/"
                            className="menu__link"
                          >
                            Vivencia
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/la-tos/que-es-la-tos/funcionamiento/"
                            className="menu__link"
                          >
                            Funcionamiento
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/la-tos/tipos-de-tos/seca/"
                        className="menu__link"
                      >
                        Tipos de tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/la-tos/tipos-de-tos/seca/"
                            className="menu__link"
                          >
                            Tos seca
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/la-tos/tipos-de-tos/con-mocos/"
                            className="menu__link"
                          >
                            Tos con moco
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                        className="menu__link"
                      >
                        Momentos de tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                            className="menu__link"
                          >
                            Gripe
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/nocturna/"
                            className="menu__link"
                          >
                            Noche
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/alergia/"
                            className="menu__link"
                          >
                            Alergia
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/verano/"
                            className="menu__link"
                          >
                            Verano
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/fumador/"
                            className="menu__link"
                          >
                            Fumador
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-leaf last leaf">
                      <a
                        href="/la-tos/como-aliviar-la-tos/"
                        className="menu__link"
                      >
                        Cómo aliviar la tos
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="menu__item is-expanded expanded">
                  <a href="/remedios/" className="menu__link">
                    Remedios contra la tos
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-leaf first leaf">
                      <a
                        href="/remedios/caseros-y-naturales/"
                        className="menu__link"
                      >
                        Remedios caseros
                      </a>
                    </li>
                    <li className="menu__item is-leaf leaf">
                      <a href="/remedios/tos-nocturna/" className="menu__link">
                        Tos nocturna
                      </a>
                    </li>
                    <li className="menu__item is-expanded last expanded">
                      <a
                        href="/remedios/contra-la-tos/seca/"
                        className="menu__link"
                      >
                        Recetas contra la tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/remedios/contra-la-tos/seca/"
                            className="menu__link"
                          >
                            Remedios tos seca
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/remedios/contra-la-tos/mocos/"
                            className="menu__link"
                          >
                            Remedios tos con moco
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="menu__item is-expanded expanded">
                  <a href="/jarabe-iniston/" className="menu__link">
                    Productos
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-expanded first expanded">
                      <a
                        href="/jarabe-iniston/expectorante-descongestivo/"
                        className="menu__link"
                      >
                        Adultos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/jarabe-iniston/expectorante-descongestivo/"
                            className="menu__link"
                          >
                            Iniston expectorante y descongestivo
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/jarabe-iniston/expectorante/"
                            className="menu__link"
                          >
                            Iniston expectorante
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/jarabe-iniston/antitusivo-descongestivo/"
                            className="menu__link"
                          >
                            Iniston antitusivo y descongestivo
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/jarabe-iniston/antitusivo/"
                            className="menu__link"
                          >
                            Iniston antitusivo
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/jarabe-iniston/mucolitico/"
                            className="menu__link"
                          >
                            Iniston mucolítico
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded last expanded">
                      <a
                        href="/jarabe-inistolin/pediatrico-expectorante/"
                        className="menu__link"
                      >
                        Niños
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/jarabe-inistolin/pediatrico-expectorante/"
                            className="menu__link"
                          >
                            Instolin pediátrico expectorante
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/jarabe-inistolin/pediatrico-antitusivo/"
                            className="menu__link"
                          >
                            Inistolin pediátrico antitusivo
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="menu__item is-expanded last expanded">
                  <a href="/tos-ninos/" className="menu__link">
                    Tos en niños
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-expanded first expanded">
                      <a
                        href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                        className="menu__link"
                      >
                        Tipos de tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                            className="menu__link"
                          >
                            Tos seca
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/tos-ninos/tipos-de-tos-ninos/productiva-con-flemas/"
                            className="menu__link"
                          >
                            Tos con mocos
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                        className="menu__link"
                      >
                        Productos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                            className="menu__link"
                          >
                            Inistolin expectorante
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/tos-ninos/jarabe-inistolin/pediatrico-antitusivo/"
                            className="menu__link"
                          >
                            Inistolin antitusivo
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-leaf leaf">
                      <a href="/tos-ninos/remedios/" className="menu__link">
                        Remedios para la tos en niños
                      </a>
                    </li>
                    <li className="menu__item is-leaf leaf">
                      <a href="/tos-ninos/causas/" className="menu__link">
                        Causas
                      </a>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/tos-ninos/desarrollo-infantil/7-anos/"
                        className="menu__link"
                      >
                        Desarrollo infantil
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/7-anos/"
                            className="menu__link"
                          >
                            7 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/8-anos/"
                            className="menu__link"
                          >
                            8 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/9-anos/"
                            className="menu__link"
                          >
                            9 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/10-anos/"
                            className="menu__link"
                          >
                            10 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/11-anos/"
                            className="menu__link"
                          >
                            11 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/12-anos/"
                            className="menu__link"
                          >
                            12 años
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-leaf last leaf">
                      <a href="/dibujos-para-colorear/" className="menu__link">
                        Diviértete con Pandolín
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="page">
          <div className="header-wrapper">
            <header className="header" id="header" role="banner">
              <div className="header-top">
                <div className="header-top-left">
                  <span id="mobile-button" />
                </div>
                <a
                  href="/"
                  title="Inicio"
                  rel="home"
                  className="header__logo"
                  id="logo"
                >
                  <img
                    src="/sites/all/themes/custom/iniston_zen/logo.png"
                    alt="Inicio"
                    className="header__logo-image"
                  />
                </a>
                <div className="region region-pre-header">
                  <div
                    id="block-search-form--2"
                    className="block block-search first odd"
                    role="search"
                  >
                    
                  </div>
                  <div
                    id="block-block-3"
                    className="block block-block last even"
                  >
                    <p>
                    <img 
                      alt="" 
                      className="attr__format__media_original img__fid__142 img__view_mode__media_original media-image header-map-pin" 
                      src="/sites/default/files/marker.png"
                    />
                      <strong>Dónde comprar Iniston</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="header__region region region-header">
                <div
                  id="block-superfish-1"
                  className="block block-superfish first last odd"
                >
                  <ul
                    id="superfish-1"
                    className="menu sf-menu sf-main-menu sf-horizontal sf-style-none sf-total-items-4 sf-parent-items-4 sf-single-items-0"
                  >
                    <li
                      id="menu-376-1"
                      className="first sf-item-1 sf-depth-1 sf-total-children-4 sf-parent-children-3 sf-single-children-1 menuparent"
                    >
                      <a href="/la-tos/" className="sf-depth-1 menuparent">
                        La tos
                      </a>
                      <ul>
                        <li
                          id="menu-382-1"
                          className="first sf-item-1 sf-depth-2 sf-total-children-3 sf-parent-children-0 sf-single-children-3 menuparent"
                        >
                          <a
                            href="/la-tos/que-es-la-tos/"
                            className="sf-depth-2 menuparent"
                          >
                            ¿Qué es la tos?
                          </a>
                          <ul>
                            <li
                              id="menu-555-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/que-es-la-tos/"
                                className="sf-depth-3"
                              >
                                Definición
                              </a>
                            </li>
                            <li
                              id="menu-540-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/que-es-la-tos/vivencia/"
                                className="sf-depth-3"
                              >
                                Vivencia
                              </a>
                            </li>
                            <li
                              id="menu-541-1"
                              className="last sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/que-es-la-tos/funcionamiento/"
                                className="sf-depth-3"
                              >
                                Funcionamiento
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-460-1"
                          className="middle sf-item-2 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/la-tos/tipos-de-tos/seca/"
                            className="sf-depth-2 menuparent"
                          >
                            Tipos de tos
                          </a>
                          <ul>
                            <li
                              id="menu-461-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/tipos-de-tos/seca/"
                                className="sf-depth-3"
                              >
                                Tos seca
                              </a>
                            </li>
                            <li
                              id="menu-462-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/tipos-de-tos/con-mocos/"
                                className="sf-depth-3"
                              >
                                Tos con moco
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-467-1"
                          className="middle sf-item-3 sf-depth-2 sf-total-children-5 sf-parent-children-0 sf-single-children-5 menuparent"
                        >
                          <a
                            href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                            className="sf-depth-2 menuparent"
                          >
                            Momentos de tos
                          </a>
                          <ul>
                            <li
                              id="menu-470-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                                className="sf-depth-3"
                              >
                                Gripe
                              </a>
                            </li>
                            <li
                              id="menu-472-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/nocturna/"
                                className="sf-depth-3"
                              >
                                Noche
                              </a>
                            </li>
                            <li
                              id="menu-474-1"
                              className="middle sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/alergia/"
                                className="sf-depth-3"
                              >
                                Alergia
                              </a>
                            </li>
                            <li
                              id="menu-476-1"
                              className="middle sf-item-4 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/verano/"
                                className="sf-depth-3"
                              >
                                Verano
                              </a>
                            </li>
                            <li
                              id="menu-478-1"
                              className="last sf-item-5 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/fumador/"
                                className="sf-depth-3"
                              >
                                Fumador
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-466-1"
                          className="last sf-item-4 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/la-tos/como-aliviar-la-tos/"
                            className="sf-depth-2"
                          >
                            Cómo aliviar la tos
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-377-1"
                      className="middle sf-item-2 sf-depth-1 sf-total-children-3 sf-parent-children-1 sf-single-children-2 menuparent"
                    >
                      <a href="/remedios/" className="sf-depth-1 menuparent">
                        Remedios contra la tos
                      </a>
                      <ul>
                        <li
                          id="menu-479-1"
                          className="first sf-item-1 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/remedios/caseros-y-naturales/"
                            className="sf-depth-2"
                          >
                            Remedios caseros
                          </a>
                        </li>
                        <li
                          id="menu-480-1"
                          className="middle sf-item-2 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/remedios/tos-nocturna/"
                            className="sf-depth-2"
                          >
                            Tos nocturna
                          </a>
                        </li>
                        <li
                          id="menu-454-1"
                          className="last sf-item-3 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/remedios/contra-la-tos/seca/"
                            className="sf-depth-2 menuparent"
                          >
                            Recetas contra la tos
                          </a>
                          <ul>
                            <li
                              id="menu-458-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/remedios/contra-la-tos/seca/"
                                className="sf-depth-3"
                              >
                                Remedios tos seca
                              </a>
                            </li>
                            <li
                              id="menu-459-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/remedios/contra-la-tos/mocos/"
                                className="sf-depth-3"
                              >
                                Remedios tos con moco
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-378-1"
                      className="middle sf-item-3 sf-depth-1 sf-total-children-2 sf-parent-children-2 sf-single-children-0 menuparent"
                    >
                      <a
                        href="/jarabe-iniston/"
                        className="sf-depth-1 menuparent"
                      >
                        Productos
                      </a>
                      <ul>
                        <li
                          id="menu-449-1"
                          className="first sf-item-1 sf-depth-2 sf-total-children-5 sf-parent-children-0 sf-single-children-5 menuparent"
                        >
                          <a
                            href="/jarabe-iniston/expectorante-descongestivo/"
                            className="sf-depth-2 menuparent"
                          >
                            Adultos
                          </a>
                          <ul>
                            <li
                              id="menu-447-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/expectorante-descongestivo/"
                                className="sf-depth-3"
                              >
                                Iniston expectorante y descongestivo
                              </a>
                            </li>
                            <li
                              id="menu-446-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/expectorante/"
                                className="sf-depth-3"
                              >
                                Iniston expectorante
                              </a>
                            </li>
                            <li
                              id="menu-445-1"
                              className="middle sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/antitusivo-descongestivo/"
                                className="sf-depth-3"
                              >
                                Iniston antitusivo y descongestivo
                              </a>
                            </li>
                            <li
                              id="menu-444-1"
                              className="middle sf-item-4 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/antitusivo/"
                                className="sf-depth-3"
                              >
                                Iniston antitusivo
                              </a>
                            </li>
                            <li
                              id="menu-443-1"
                              className="last sf-item-5 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/mucolitico/"
                                className="sf-depth-3"
                              >
                                Iniston mucolítico
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-451-1"
                          className="last sf-item-2 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/jarabe-inistolin/pediatrico-expectorante/"
                            className="sf-depth-2 menuparent"
                          >
                            Niños
                          </a>
                          <ul>
                            <li
                              id="menu-442-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-inistolin/pediatrico-expectorante/"
                                className="sf-depth-3"
                              >
                                Instolin pediátrico expectorante
                              </a>
                            </li>
                            <li
                              id="menu-441-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-inistolin/pediatrico-antitusivo/"
                                className="sf-depth-3"
                              >
                                Inistolin pediátrico antitusivo
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-379-1"
                      className="last sf-item-4 sf-depth-1 sf-total-children-6 sf-parent-children-3 sf-single-children-3 menuparent"
                    >
                      <a href="/tos-ninos/" className="sf-depth-1 menuparent">
                        Tos en niños
                      </a>
                      <ul>
                        <li
                          id="menu-482-1"
                          className="first sf-item-1 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                            className="sf-depth-2 menuparent"
                          >
                            Tipos de tos
                          </a>
                          <ul>
                            <li
                              id="menu-485-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                                className="sf-depth-3"
                              >
                                Tos seca
                              </a>
                            </li>
                            <li
                              id="menu-484-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/tipos-de-tos-ninos/productiva-con-flemas/"
                                className="sf-depth-3"
                              >
                                Tos con mocos
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-546-1"
                          className="middle sf-item-2 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                            className="sf-depth-2 menuparent"
                          >
                            Productos
                          </a>
                          <ul>
                            <li
                              id="menu-553-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                                className="sf-depth-3"
                              >
                                Inistolin expectorante
                              </a>
                            </li>
                            <li
                              id="menu-554-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/jarabe-inistolin/pediatrico-antitusivo/"
                                className="sf-depth-3"
                              >
                                Inistolin antitusivo
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-537-1"
                          className="middle sf-item-3 sf-depth-2 sf-no-children"
                        >
                          <a href="/tos-ninos/remedios/" className="sf-depth-2">
                            Remedios para la tos en niños
                          </a>
                        </li>
                        <li
                          id="menu-487-1"
                          className="middle sf-item-4 sf-depth-2 sf-no-children"
                        >
                          <a href="/tos-ninos/causas/" className="sf-depth-2">
                            Causas
                          </a>
                        </li>
                        <li
                          id="menu-488-1"
                          className="middle sf-item-5 sf-depth-2 sf-total-children-6 sf-parent-children-0 sf-single-children-6 menuparent"
                        >
                          <a
                            href="/tos-ninos/desarrollo-infantil/7-anos/"
                            className="sf-depth-2 menuparent"
                          >
                            Desarrollo infantil
                          </a>
                          <ul>
                            <li
                              id="menu-501-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/7-anos/"
                                className="sf-depth-3"
                              >
                                7 años
                              </a>
                            </li>
                            <li
                              id="menu-498-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/8-anos/"
                                className="sf-depth-3"
                              >
                                8 años
                              </a>
                            </li>
                            <li
                              id="menu-497-1"
                              className="middle sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/9-anos/"
                                className="sf-depth-3"
                              >
                                9 años
                              </a>
                            </li>
                            <li
                              id="menu-495-1"
                              className="middle sf-item-4 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/10-anos/"
                                className="sf-depth-3"
                              >
                                10 años
                              </a>
                            </li>
                            <li
                              id="menu-493-1"
                              className="middle sf-item-5 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/11-anos/"
                                className="sf-depth-3"
                              >
                                11 años
                              </a>
                            </li>
                            <li
                              id="menu-490-1"
                              className="last sf-item-6 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/12-anos/"
                                className="sf-depth-3"
                              >
                                12 años
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-439-1"
                          className="last sf-item-6 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/dibujos-para-colorear/"
                            className="sf-depth-2"
                          >
                            Diviértete con Pandolín
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </header>
          </div>
          <div id="main">
            <div id="content" className="column" role="main">
              <nav className="breadcrumb" role="navigation">
                <h2 className="element-invisible">Se encuentra usted aquí</h2>
                <ol>
                  <li>
                    <a href="/">Inicio</a> |{" "}
                  </li>
                  <li>Política de Privacidad</li>
                </ol>
              </nav>{" "}
              <a id="main-content" />
              <h1 className="page__title title" id="page-title">
                <span>Política de Privacidad</span>
              </h1>
              <article className="node-99 node node-page view-mode-full no-image clearfix">
                <div className="node-content-wrapper">
                  <div className="node-content">
                    <div className="left">
                      <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            <p>
                            Johnson &amp; Johnson S.A. se preocupa por su privacidad y desea que esté familiarizado con cómo recopilamos, utilizamos y divulgamos información.  Esta Política de privacidad describe nuestras prácticas en relación con la información que nosotros o nuestros proveedores de servicios recopilan a través del sitio web o aplicación (en adelante el “Servicio”) operados y controlados por nosotros, desde los cuales usted accede a esta Política de privacidad.  Al proporcionarnos información personal o utilizar el Servicio, usted declara que ha leído y comprendido esta Política de privacidad.
                            </p>
                            <p>
                              <strong>USO POR PARTE DE MENORES DE EDAD</strong>
                            </p>
                            <p>
                            El Servicio no está dirigido a personas menores de 14 años, y le pedimos a estas personas que no proporcionen información personal en el Servicio. Si su hijo ha enviado Información personal y usted desea solicitar que dicha Información personal sea eliminada, por favor contáctenos tal y como se explica más adelante en la sección Contactar con nosotros.
                            </p>
                            <p>
                              <strong>RECOGIDA DE INFORMACIÓN</strong>
                            </p>
                            <p>
                            Podemos solicitarle que envíe información personal para que se beneficie de ciertas características (como suscripciones a boletines, consejos/indicadores o procesamiento de pedidos) o para participar en una actividad particular (como sorteos u otras promociones).  Se le comunicará qué información es necesaria y cuál es opcional.
                            </p>
                            <p>
                            Podremos combinar la información que usted proporcione con otros datos que hayamos obtenido de usted, ya sea en línea o no, incluido, por ejemplo, su historial de compra.  También podemos combinarla con información sobre usted que recibamos de otras filiales de Johnson &amp; Johnson .
                            </p>
                            <p>
                            Si nos envía cualquier información personal relativa a otro individuo, usted declara que tiene autorización para hacerlo y para permitirnos usar la información de acuerdo con esta Política de privacidad.
                            </p>
                            <p>
                              <strong>INFORMACIÓN SENSIBLE</strong>
                            </p>
                            <p>
                            Salvo que se lo solicitemos o se le invite específicamente, le pedimos que no nos envíe ni divulgue información personal sensible (p. ej., número de la seguridad social, información relacionada con el origen racial o étnico, opiniones políticas, religión u otras creencias, salud o afecciones médicas, vida sexual u orientación sexual, antecedentes penales o asociación sindical, o datos genéticos o biométricos) en el Servicio o a través de este, o de otro modo a nosotros.
                            </p>
                            <p>
                              <strong>
                              USO Y RECOPILACIÓN AUTOMÁTICA DE INFORMACIÓN
                              </strong>
                            </p>
                            <p>
                            Nosotros y nuestros proveedores de servicios podemos recopilar cierta información de forma automática mientras navega por el Servicio.  Lea la{" "}
                              <a href="/politica-de-cookies/" target="_blank">
                                Política de cookies
                              </a>{" "}
                              para obtener información detallada sobre las cookies y otras tecnologías de rastreo empleadas en el Servicio. Excepto cuando lo permita la ley aplicable, instalaremos las cookies una vez hayamos recibido su consentimiento mediante el banner de cookies o su centro de preferencias. La Política de cookies incluye información sobre cómo puede desactivar estas tecnologías.  
                            </p>
                            <p>
                            Nosotros y nuestros proveedores de servicios también podemos usar y recopilar automáticamente información de las siguientes formas:
                            </p>
                            <p>
                              <strong>A través de su navegador:</strong> La mayoría de los navegadores recopilan cierta información, como su dirección de Control de Acceso a Medios (Media Access Control, MAC), tipo de ordenador (Windows o Mac), resolución de pantalla, nombre y versión del sistema operativo, y el tipo y la versión del navegador de Internet.  Podremos recopilar información similar, como su tipo de dispositivo e identificador, si accede al Servicio a través de un dispositivo móvil.  Utilizamos esta información para asegurar que el Servicio funcione adecuadamente.
                            </p>
                            <p>
                              <strong>Dirección IP:</strong> Su dirección IP es un número que su Proveedor de Servicios de Internet (Internet Service Provider, ISP) asigna automáticamente a su ordenador.  La dirección IP se identifica y registra automáticamente en los archivos de registro de nuestro servidor cada vez que un usuario visita el Servicio, junto con la hora de su visita y las páginas que visitó.  La recogida de direcciones IP es una práctica estándar y muchos servicios en línea la realizan de forma automática.  Usamos las direcciones IP para calcular los niveles de uso del Servicio, diagnosticar problemas en servidores y administrar el Servicio.  También podemos deducir su ubicación aproximada desde su dirección IP.
                            </p>
                            <p>
                              <strong>Información de dispositivos:</strong>{" "}
                              Podemos recopilar información sobre su dispositivo móvil, como el identificador único de dispositivo, para entender cómo utiliza el servicio.
                            </p>
                            <ul />
                            <p>
                              <strong>
                              CÓMO USAMOS Y DIVULGAMOS LA INFORMACIÓN
                              </strong>
                            </p>
                            <p>
                            Usamos y divulgamos la información que nos proporciona según lo mencionado en el momento de la recopilación.  Por favor consulte la sección titulada “Opciones y accesos” más adelante para saber cómo puede quedar excluido de ciertos de nuestros usos y divulgaciones.
                            </p>
                            <p>
                            Cuando lo requiera la legislación aplicable, obtendremos su consentimiento para el uso que hagamos de su información personal en el momento de la recopilación de información. Asimismo, podemos usar información suya o sobre usted tal y como sea necesario para ejecutar un contrato, cumplir una obligación legal (por ejemplo, debido a nuestras obligaciones sobre farmacovigilancia) o para nuestros intereses comerciales legítimos.  También podemos basarnos en otras bases legales, específicamente para:
                            </p>
                            <ul>
                              <li>
                                <strong>
                                Proporcionar la funcionalidad del Servicio y atender sus solicitudes.
                                </strong>
                                <ul>
                                  <li>
                                    para proporcionarle la funcionalidad del Servicio y proporcionarle un servicio al cliente relacionado;
                                  </li>
                                  <li>
                                    para responder a sus consultas y atender sus solicitudes, como envío de documentos que solicite o alertas por correo electrónico;
                                  </li>
                                  <li>
                                    para enviarle información importante sobre nuestra relación con usted o sobre el Servicio, cambios en nuestros términos, condiciones y políticas y/o información administrativa.
                                  </li>
                                </ul>
                              </li>
                            </ul>
                            <p>Participaremos en estas actividades para administrar nuestra relación contractual con usted y/o cumplir con una obligación legal.</p>
                            <ul>
                              <li>
                                <strong>
                                Lograr nuestros fines comerciales.
                                </strong>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                para el análisis de datos, por ejemplo, para mejorar la eficiencia del Servicio;
                              </li>
                              <li>
                                para auditorías, para verificar que nuestros procesos internos funcionan según lo previsto y que cumplen con los requisitos legales, normativos o contractuales;
                              </li>
                              <li>
                                para fines de supervisión de fraude y seguridad, por ejemplo, para detectar y prevenir ataques cibernéticos o intentos de cometer robos de identidad;
                              </li>
                              <li>
                                para desarrollar nuevos productos y servicios;
                              </li>
                              <li>
                                para potenciar, mejorar o modificar nuestro sitio web o productos y servicios; 
                              </li>
                              <li>
                                para identificar las tendencias de uso del Servicio, por ejemplo, comprender qué partes de nuestro Servicio son de mayor interés para los usuarios; y
                              </li>
                              <li>
                                para determinar la efectividad de nuestras campañas promocionales, para que podamos adaptar nuestras campañas a las necesidades e intereses de nuestros usuarios.
                              </li>
                            </ul>
                            <p>
                            Participaremos en estas actividades para administrar nuestra relación contractual con usted, cumplir con una obligación legal y/o porque tenemos un interés legítimo.
                            </p>
                            <ul>
                              <li>
                                <strong>
                                  Análisis de información personal para proporcionar servicios personalizados.
                                </strong>
                                <ul>
                                  <li>
                                    para comprenderle mejor, de modo que podamos personalizar nuestras interacciones con usted y proporcionarle información y/u ofertas adaptadas a sus intereses;
                                  </li>
                                  <li>
                                    para comprender mejor sus preferencias de modo que podamos entregar contenido a través del Servicio que creemos será relevante e interesante para usted.
                                  </li>
                                </ul>
                                <p>
                                Proporcionaremos servicios personalizados con su consentimiento o porque tenemos un interés legítimo.
                                </p>
                              </li>
                            </ul>
                            <p>
                            También divulgamos información recopilada a través del Servicio:
                            </p>
                            <ul>
                              <li>
                                a nuestras filiales para los fines descritos en esta Política de privacidad.  Puede encontrar una lista de nuestras filiales en{" "}
                                <a href="https://johnsonandjohnson.gcs-web.com/financial-information/sec-filings">
                                https://johnsonandjohnson.gcs-web.com/financial-information/sec-filings
                                </a>{" "}
                                (haga clic en el Formulario 10k, Documento 21 (Exhibit 21), en “SEC Filings”)  Johnson &amp; Johnson, S.A. se responsabiliza de la administración de la información personal tratada conjuntamente;
                              </li>
                              <li>
                                a nuestros socios externos con los que ofrecemos promociones de marcas compartidas o comercialización conjunta;
                              </li>
                              <li>
                                a nuestros proveedores de servicios externos que proporcionan servicios, como hospedaje y moderación web, hospedaje de aplicaciones para móviles, análisis de datos, procesamiento de pagos, envío de pedidos, provisión de infraestructura, servicios de TI, servicio al cliente, servicios de provisión de correo electrónico y correo directo, auditoría y otros servicios, a fin de permitirles proporcionar servicios; y
                              </li>
                              <li>
                                tal y como permita la legislación aplicable, a un tercero en caso de restructuración, fusión, venta, unión de sociedades, sesión, transferencia u otra distribución de cualquier parte de nuestro negocio, activo o acción (incluyendo procedimientos de quiebra o similares).
                              </li>
                            </ul>
                            <p>
                            Además, podemos emplear y divulgar su información según consideremos necesario o adecuado: (a) para cumplir con procesos legales o con la legislación aplicable, que puede incluir leyes fuera de su país de residencia; (b) como permita la legislación aplicable, para responder ante solicitudes de las autoridades públicas y gubernamentales, que pueden tratarse de autoridades fuera de su país de residencia; (c) para hacer cumplir nuestros términos y condiciones; y (d) para proteger nuestros derechos, privacidad, seguridad o propiedad o los de nuestras filiales, los de usted u los de otros.  También podemos usar y divulgar su información de otras formas tras obtener su consentimiento para ello.
                            </p>
                            <p>
                            Podemos usar y divulgar información que recopilemos automáticamente tal y como se describe anteriormente en “Uso y Recopilación automática de información”.
                            </p>
                            <p>
                            Además, cuando lo permita la legislación aplicable, podemos usar y divulgar información no personal con cualquier fin.  Si combinamos información personal en estado no identificable con información que sí es identificable (como combinar su nombre con su ubicación geográfica), trataremos la información combinada como personal mientras esté combinada.
                            </p>
                            <p>
                              <strong>OPCIONES Y ACCESO</strong>
                            </p>
                            <p>
                              <strong>
                              Sus opciones sobre el uso y la divulgación de su información personal
                              </strong>
                            </p>
                            <p>
                            Le proporcionamos opciones sobre el uso y la divulgación de su información personal con fines de marketing.  Usted puede optar por lo siguiente:
                            </p>
                            <ul>
                              <li>
                                <strong>
                                  Recibir nuestras comunicaciones comerciales:  
                                </strong>{" "}
                                Si no desea recibir más nuestras comunicaciones comerciales en el futuro, podrá oponerse a su recepción contactándonos por correo electrónico haciendo{" "}
                                <a
                                  href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL"
                                  target="_blank"
                                >
                                  click aquí
                                </a>
                                . En su solicitud, le rogamos que facilite su nombre, identifique la(s) modalidad(es) de comunicaciones de marketing que no desea recibir e incluya la(s) dirección/direcciones a la(s) que se la(s) envió/enviaron.  Por ejemplo, si no desea recibir nuestras comunicaciones comerciales por correo electrónico o correo directo, indíquelo y facilite su nombre así como correo electrónico o dirección postal.  Además, puede oponerse a recibir nuestras comunicaciones comerciales por correo electrónico siguiendo las instrucciones de cancelación de la suscripción, que se proporcionan en el mensaje.
                              </li>
                              <li>
                                <strong>
                                  Recepción de nuestros recordatorios:
                                </strong>{" "}
                                Si no desea recibir más recordatorios médicos de nosotros en el futuro, podrá oponerse a su recepción contactándonos por correo electrónico haciendo{" "}
                                <a
                                  href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL"
                                  target="_blank"
                                >
                                  click aquí
                                </a>
                                . En su comunicación, facilite su nombre y dirección de correo electrónico o número de teléfono en el que recibe los recordatorios.
                              </li>
                              <li>
                                <strong>
                                  Comunicación de su información personal a filiales y socios externos:
                                </strong>{" "}
                                Si optó previamente por recibir comunicaciones comerciales de nuestras filiales o socios externos, usted puede oponerse a que compartamos su información personal con dichas partes para fines publicitarios en el futuro contactándonos por correo electrónico haciendo{" "}
                                <a
                                  href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL"
                                  target="_blank"
                                >
                                  click aquí
                                </a>
                                . En su comunicación, le rogamos que nos indique que no debemos comunicar su información personal a nuestras filiales y/o socios externos con fines publicitarios, e incluya su nombre y dirección de correo electrónico.
                              </li>
                            </ul>
                            <p>
                            Trataremos de cumplir con su(s) solicitud(es) lo antes posible.  Cabe señalar que si se opone tal como se describió anteriormente, no podremos eliminar su información personal de las bases de datos de nuestras filiales con las que ya hayamos compartido su información personal (es decir, a la fecha de su solicitud de oposición). No obstante, haremos esfuerzos razonables para informar a nuestras filiales de su solicitud. Tenga en cuenta que, si se opone a recibir comunicaciones comerciales de nosotros, sigue siendo posible que le enviemos importantes comunicaciones administrativas y sobre transacciones, respecto de los cuales no puede oponerse.
                            </p>
                            <p>
                              <strong>
                              Cómo puede acceder, cambiar o eliminar su información personal
                              </strong>
                            </p>
                            <p>
                            Si usted quisiera revisar, corregir, actualizar, limitar o eliminar su información personal, o si quisiera solicitar una copia electrónica de su información personal con la finalidad de transmitirla a otra empresa (hasta donde la legislación aplicable le conceda estos derechos) contáctenos por correo electrónico haciendo{" "}
                              <a
                                href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL"
                                target="_blank"
                              >
                                click aquí
                              </a>
                              . Responderemos a su solicitud tan pronto como sea razonablemente posible y no más tarde de un mes tras la recepción. Para ayudarle en caso de demora en nuestra respuesta, se le notificará rápidamente y se le proporcionará la fecha en la que le daremos respuesta. 
                            </p>
                            <p>
                              <strong>TRANSFERENCIA INTERNACIONAL</strong>
                            </p>
                            <p>
                            Su información personal podrá almacenarse y procesarse en cualquier país en que tengamos instalaciones o proveedores de servicios, y al usar nuestro Servicio o proporcionarnos su consentimiento (cuando la ley lo exija), su información podrá ser transferida a países fuera de su país de residencia, incluyendo los Estados Unidos, que podrán establecer diferentes normas de protección de datos distintas a las de su país.  Sin embargo, existen medidas contractuales apropiadas y de otro tipo implementadas para proteger la información personal cuando se transfiere a nuestras filiales o socios externos en otros países.
                            </p>
                            <p>
                            La Comisión Europea reconoce a algunos países fuera del Espacio Económico Europeo (EEE) como países que proporcionan un nivel de protección de datos adecuado de acuerdo con los estándares del EEE (la lista completa de estos países está disponible{" "}
                              <a
                                href="https://ec.europa.eu/info/law/law-topic/data-protection/adequacy-decisions_es"
                                target="_blank"
                              >
                                aquí
                              </a>
                              . En el caso de transferencias desde el EEE a países considerados no adecuados por la Comisión Europea, nos hemos asegurado de que existan medidas adecuadas implementadas, incluyendo la garantía de que el receptor esté vinculado por cláusulas contractuales tipo de la UE, para proteger su Información personal.  Usted puede obtener una copia de estas medidas contactando con nuestro Delegado de Protección de Datos, de acuerdo con las instrucciones en la sección <i>“Contactar con nosotros”</i> (Contacting Us) abajo.
                            </p>
                            <p>
                              <strong>SEGURIDAD</strong>
                            </p>
                            <p>
                            Buscamos implementar medidas razonables organizativas, técnicas y administrativas diseñadas para proteger la información personal bajo nuestro control.  Lamentablemente, no se puede garantizar la seguridad total de ninguna transmisión de datos por Internet o de sistemas de almacenamiento de datos.  Si tiene motivos para creer que su interacción con nosotros ya no es segura (por ejemplo, si considera que la seguridad de una cuenta que tiene con nosotros ha sido comprometida), le rogamos que nos lo notifique de inmediato de acuerdo con las instrucciones en la sección <i>“Contactar con nosotros”</i> (Contacting Us) abajo.
                            </p>
                            <p>
                              <strong>PERIODO DE RETENCIÓN</strong>
                            </p>
                            <p>
                            Retendremos su información personal durante el tiempo que sea necesario o esté permitido de acuerdo con el fin o los fines para los que se obtuvo.  Los criterios utilizados para determinar nuestros periodos de retención incluyen: (i) el periodo de tiempo por el que tenemos una relación con usted y le proporcionamos el Servicio; (ii) si existe una obligación legal a la que estamos sometidos; y (iii) si la retención es aconsejable teniendo en cuenta nuestra posición legal (lo relativo a los plazos aplicables de prescripción, litigio o investigaciones reguladoras).
                            </p>
                            <p>
                              <strong>SITIOS Y SERVICIOS DE TERCEROS</strong>
                            </p>
                            <p>
                            El Servicio puede contener vínculos a sitios de terceros. Esta Política de privacidad no trata ni tampoco nos responsabilizamos por la privacidad, información o prácticas de terceros, incluidos terceros que operan cualquier sitio o servicio en línea (entre otros, cualquier aplicación) que esté disponible a través de este Servicio o de un enlace incluido en este Servicio.  La disponibilidad de cualquier sitio o presencia en el Servicio, o la inclusión de un enlace a estos, no implica su aprobación por nosotros o nuestras filiales.
                            </p>
                            <p>
                              <strong>CONTÁCTENOS</strong>
                            </p>
                            <p>
                            Johnson &amp; Johnson S.A., en el Paseo de las Doce Estrellas, 5-7, 28042 Madrid, es la empresa responsable de la recopilación, uso y divulgación de información personal de acuerdo con esta Política de privacidad.
                            </p>
                            <p>
                            Si tiene alguna pregunta sobre esta Política de privacidad, contáctenos por correo electrónico haciendo{" "}
                              <a
                                href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL#etd=::00c?Z9W00Y00r_800ww$"
                                target="_blank"
                              >
                                click aquí
                              </a>
                              , o por correo postal a la siguiente dirección:
                            </p>
                            <p>
                              Servicio de Atención al Consumidor
                              <br />
                              División Consumo
                              <br />
                              Paseo de las Doce Estrellas, 5-7
                              <br />
                              28042 Madrid.
                            </p>
                            <p>
                            También puede ponerse en contacto con el Delegado de Protección de Datos a cargo de su país o región, si procede, a través de{" "}
                              <a href="mailto:emeaprivacy@its.jnj.com">
                                <strong>emeaprivacy@its.jnj.com</strong>.
                              </a>
                            </p>
                            <p>
                              <strong>
                              PRESENTAR UNA RECLAMACIÓN ANTE LA AUTORIDAD REGULADORA
                              </strong>
                            </p>
                            <p>
                            Usted puede presentar una reclamación ante una autoridad supervisora competente en su país o región.  Por favor haga clic{" "}
                              <a
                                href="https://edpb.europa.eu/about-edpb/about-edpb/members_es"
                                target="_blank"
                              >
                               aquí
                              </a>{" "}
                              para acceder a la información de contacto de dichas autoridades. 
                            </p>
                            <p>
                              <strong>
                              ACTUALIZACIONES DE ESTA POLÍTICA DE PRIVACIDAD
                              </strong>
                            </p>
                            <p>
                            Podremos modificar esta Política de privacidad.  Toda modificación de nuestra Política de privacidad entrará en vigor con la publicación de la Política de privacidad revisada en el Servicio.  Si utiliza el Servicio después de estas modificaciones, esto significa que acepta la Política de Privacidad revisada.  Recomendamos que revise la Política de Privacidad de forma regular cuando visite el Servicio. Esta Política se actualizó por última vez el 22-09-2021.
                            </p>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div className="right"></div>
                  </div>
                  <div></div>
                </div>
              </article>
            </div>
            <div id="navigation"></div>
          </div>
          <footer id="footer" className="region region-footer">
            <div
              id="block-menu-menu-menu-footer"
              className="block block-menu first odd"
              role="navigation"
            >
              <ul className="menu">
                <li className="menu__item is-leaf first leaf">
                  <a href="/contacto/" className="menu__link">
                    Contacto
                  </a>
                </li>
                <li className="menu__item is-active-trail is-leaf leaf active-trail">
                  <a
                    href="/politica-de-privacidad/"
                    className="menu__link is-active-trail active-trail active"
                  >
                    política de privacidad
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/politica-de-cookies/" className="menu__link">
                    política de cookies
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/aviso-legal/" className="menu__link">
                    Aviso legal
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/acceso-profesionales/" className="menu__link">
                    Acceso profesionales
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/preguntas-frecuentes/" className="menu__link">
                    Faqs
                  </a>
                </li>
                <li className="menu__item is-leaf last leaf">
                  <a href="#" id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow">
                    Configuración de cookies
                  </a>
                </li>
              </ul>
            </div>
            <div id="block-block-1" className="block block-block last even">
              <p className="rtecenter">
                Publicada por Johnson &amp; Johnson S.A., único responsable de
                su contenido está dirigida a residentes en España.
                <br />
                ©&nbsp;Johnson &amp; Johnson S.A, 2015. Última actualización
                20/12/2014
              </p>
            </div>
          </footer>
        </div>
        <script src="/sites/default/files/js/js_pMpn-aGMsGKgMnAtmxLe-SZAZXUOnXxQqYAoiRN9DJI.js" />
        <script
          dangerouslySetInnerHTML={{
            __html: 'var eu_cookie_compliance_cookie_name = "";'
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction read_cookie(k,r){return(r=RegExp('(^|; )'+encodeURIComponent(k)+'=([^;]*)').exec(document.cookie))?r[2]:null;}\nvar cookie_agreed = read_cookie('cookie-agreed');\nif (cookie_agreed == 2) {\n/* <!--[CDATA[ */\nvar google_conversion_id = 1004307202;\nvar google_custom_params = window.google_tag_params;\nvar google_remarketing_only = true;\n/* ]]--> */\n}\n"
          }}
        />
        <script
          type="text/javascript"
          src="//www.googleadservices.com/pagead/conversion.js"
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n  <div style="display:inline">\n    <img height="1" width="1" style="border-style:none" alt="" src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/1004307202/?value=0&amp;guid=ON&amp;script=0">\n  </div>\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
